import { PropsWithChildren } from 'react';
import { SiteNav } from './Navigation/SiteNav';
import { SiteFooter } from './SiteFooter';

interface Props {
  minimal?: boolean;
  // For "app" layouts, we grow the body pane to take the entire viewport,
  // but don't permit scrolling. Think the preview dialog, eg.
  fixed?: boolean;
  wide?: boolean;
}

const classNames = (...classes: (undefined | string | boolean)[]): string =>
  classes.filter((x) => x && x !== true).join(' ');

export const SiteLayout = (props: PropsWithChildren<Props>) => {
  const { fixed, minimal, wide, children } = props;

  return (
    <div className="flex flex-col h-screen ">
      {/* This empty <div/> avoid flex layout affecting the navbar */}
      <SiteNav minimal={minimal} />
      {/* the grow forces the footer to the bottom when content is too short here */}
      <div className="grow relative">
        <div
          className={classNames(
            !wide && 'max-w-(--breakpoint-xl) mx-auto',
            !minimal ? 'py-5 px-5 lg:px-24' : 'px-5',
            fixed && 'absolute top-0 bottom-0 left-0 right-0'
          )}
        >
          {children}
        </div>
      </div>
      <div className={minimal ? '' : 'mt-10 md:mt-20'}>
        <SiteFooter minimal={props.minimal} />
      </div>
    </div>
  );
};
